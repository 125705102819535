import { compact } from 'lodash'
import { UseFormReturn } from 'react-hook-form'
import { Fields } from '@templates/AddNewItemModal/Content'
import useJobFields from '@hooks/useJobFields'
import {
  checkboxField,
  dateField,
  employeeField,
  selectField,
} from '@templates/AddNewItemModal/templates'
import { Option } from '@organisms/Select'
import useCoursesSelectField from '@hooks/useCoursesSelectField'
import { EducationReports } from '@pages/EmployeesEducation/useEmployeesEducationReports'

const getYearsOptions = (): Option[] => {
  const nowYear = new Date().getFullYear()

  const result: Option[] = []
  for (let i = nowYear; i >= 2000; i--) {
    result.push({
      label: i.toString(),
      value: i,
    })
  }

  return result
}

const useEmployeesEducationsFiltersModalFields = (
  selectedReport: EducationReports | undefined,
  methods: UseFormReturn
): Fields => {
  const { company, filial, division, position } = useJobFields(methods)
  const selectCourseField = useCoursesSelectField()

  const interval: Option | undefined = methods.watch('interval')

  let periodValueFields = null
  switch (interval?.value) {
    case 'year': {
      periodValueFields = [
        selectField({
          name: 'year',
          label: 'Год',
          defaultValue: {
            value: new Date().getFullYear(),
            label: new Date().getFullYear(),
          },
          options: getYearsOptions(),
          withoutFetchOptions: true,
        }),
      ]

      break
    }
    case 'period': {
      periodValueFields = [
        dateField({
          label: 'От',
          name: 'after',
        }),
        dateField({
          label: 'До',
          name: 'before',
        }),
      ]

      break
    }
    case 'date': {
      periodValueFields = [
        dateField({
          label: 'Дата',
          name: 'date',
        }),
      ]

      break
    }
    // No default
  }

  if (selectedReport === EducationReports.POSITIONS_REPORT_NAME) {
    return [
      {
        fields: compact([
          company?.({
            isRequired: true,
          }),
          filial?.({
            isRequired: false,
          }),
          division?.({
            isRequired: false,
          }),
          position?.({
            isRequired: false,
          }),
        ]),
      },
    ]
  }

  if (selectedReport === EducationReports.EMPLOYEES_REPORT) {
    return [
      {
        fields: compact([
          company?.({
            isRequired: false,
          }),
          filial?.({
            isRequired: false,
          }),
          division?.({
            isRequired: false,
          }),
          position?.({
            isRequired: false,
          }),
        ]),
      },
      {
        fields: [
          selectCourseField({
            label: 'Курс',
            name: 'course',
          }),
          dateField({
            label: 'Пройден курс от',
            name: 'passedCourseFrom',
          }),
          dateField({
            label: 'Пройден курс до',
            name: 'passedCourseTo',
          }),
        ],
      },
      {
        fields: [
          checkboxField({
            name: 'courseIncomplete',
            label: 'Только незавершённые обучения',
          }),
        ],
      },
    ]
  }

  return [
    {
      fields: compact([
        company?.({
          isRequired: false,
        }),
        filial?.({
          isRequired: false,
        }),
        division?.({
          isRequired: false,
        }),
        position?.({
          isRequired: false,
        }),
      ]),
    },
    {
      fields: compact([
        employeeField({
          name: 'conductsBriefing',
          label: 'проводит инструктаж',
        }),
        selectField({
          name: 'interval',
          label: 'Интервал дат',
          options: [
            {
              label: 'Год',
              value: 'year',
            },
            {
              label: 'Период',
              value: 'period',
            },
            {
              label: 'Дата',
              value: 'date',
            },
          ],
          withoutFetchOptions: true,
        }),
        ...(periodValueFields || []),
      ]),
    },
  ]
}

export default useEmployeesEducationsFiltersModalFields
