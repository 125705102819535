import { WorkPlaceDetailed } from '@models/workPlace'
import { FormValues } from './index'

const getDefaultValues = (
  workPlace?: WorkPlaceDetailed
): Partial<FormValues> | void => {
  if (!workPlace) return

  return {}
}

export default getDefaultValues
