export const INITIAL_BRIEFING_REPORT_NAME =
  'Журнал регистрации вводного инструктажа по охране труда'
export const OTHER_BRIEFING_REPORT_NAME =
  'Журнал регистрации первичного инструктажа, повторный инструктаж с ' +
  'назначением определённого периода 1 раз в 3/6/12 месяцев'

export const BODY_CHECK_REPORT_NAME = 'Медосмотр'
export const EMPLOYEES_EDUCATION_STATS_REPORT_NAME = 'Статистика обучений'
export const POSITIONS_REPORT_NAME =
  'Перечень должностей ответственных за инструктажи'
export const LINKS_FOR_PASSWORDS_NAME = 'Ссылки для задания паролей сотрудникам'
export const ANTI_FIRE_REPORT_NAME = 'Журнал учета противопожарных инструктажей'
export const ELECTRO_SAFE_REPORT_NAME =
  'Журнал присвоения I группы по электробезопасности неэлектротехническому персоналу'

export const DANGEROUS_REGISTRY = 'Реестр опасностей'
export const RISK_MANAGEMENT_PLAN = 'План мер по управлению рисками'
export const ACK_WITH_RISK_MAPS = 'Ознакомление с картами риска'
export const POSITIONS_WITHOUT_RISK_MAPS = 'Должности без карты рисков'
export const RISK_MANAGEMENT_SYSTEM =
  'Положение: система управления профессиональными рисками'
export const SIZ = 'Средства индивидуальной защиты'

export const OPENED_REPORT_QUERY = 'openedReport'

export const BODY_CHECK_REPORT = 'body_check'
export const LINKS_REPORT = 'recovery_links_report'
export const EMPLOYEES_EDUCATION_STATS_REPORT = 'employees'
export const INITIAL_BRIEFING_REPORT = 'initial_briefing_report'
export const OTHER_BRIEFING_REPORT = 'multiple_briefing_report'
export const ANTI_FIRE_REPORT = 'fire_safety_briefing_report'
export const ELECTRO_SAFE_REPORT = 'electrical_safety_non_electric_report'
export const POSITIONS_REPORT = 'conduct_job_position_report'
export const DANGEROUS_REGISTRY_REPORT = 'risk_map_danger_report'
export const RISK_MANAGEMENT_PLAN_REPORT =
  'risk_map_control_measure_plan_report'
export const ACK_WITH_RISK_MAPS_REPORT = 'risk_map_employee_review_report'
export const POSITIONS_WITHOUT_RISK_MAPS_REPORT =
  'job_position_without_risk_map_report'
export const RISK_MANAGEMENT_SYSTEM_REPORT =
  'professional_risk_management_system_report'
export const SIZ_REPORT = 'protection_means_report'
