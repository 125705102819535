import { FC, useEffect } from 'react'
import { animated, useSpring } from 'react-spring'
import { useToggle } from 'react-use'
import { useMediaQuery } from 'react-responsive'
import { useTheme } from '@emotion/react'
import { DEFAULT_ANIMATION_DURATION } from '@const/animation'
import { isMobile } from '@const/device'
import { useGetBlobFileQuery } from '@services/files'
import useMe from '@hooks/useMe'
import { ReactComponent as Logo } from '@interface-images/main-logo.svg'
import { ReactComponent as SmallLogo } from '@interface-images/main-logo-small.svg'
import FooterLinks from '../FooterLinks'
import MainMenu from '../MainMenu'
import { xl } from '../../../theme/breakpoints'
import ExpandButton from '../ExpandButton'
import { LogoWrapper, MenuWrapper } from './styled'

export const SMALL_WIDTH = 45
export const BIG_WIDTH_MENU = 285

type Props = {
  onExpandMenu: (expanded: boolean) => void
}

const DesktopMenu: FC<Props> = ({ onExpandMenu }) => {
  const { company } = useMe()
  const theme = useTheme()
  const isTablet = useMediaQuery({ query: `(max-width: ${xl})` })

  const { data: logoUrl } = useGetBlobFileQuery(
    company?.logo?.filePath as string,
    {
      skip: !company?.logo?.filePath,
    }
  )

  const [isSmall, toggleIsSmall] = useToggle(isTablet)
  const [smallVisible, toggleSmallVisible] = useToggle(isTablet)

  useEffect(() => {
    setTimeout(
      () => {
        onExpandMenu(isSmall)
      },
      isSmall ? DEFAULT_ANIMATION_DURATION : 0
    )
  }, [isSmall])

  useEffect(() => {
    toggleIsSmall(isTablet)
  }, [isTablet])

  const { x } = useSpring({
    x: isSmall ? SMALL_WIDTH : BIG_WIDTH_MENU,
    config: { duration: DEFAULT_ANIMATION_DURATION },
    onRest: toggleSmallVisible,
  })

  return (
    <animated.div
      style={{
        backgroundColor: theme.colors.background.surface,
        borderRadius: '0 30px 30px 0',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        height: '100vh',
        whiteSpace: 'nowrap',
        padding: '30px 0 30px 15px',
        width: x.to({
          range: [SMALL_WIDTH, BIG_WIDTH_MENU],
          output: [SMALL_WIDTH, BIG_WIDTH_MENU],
        }),
        maxWidth: x.to({
          range: [SMALL_WIDTH, BIG_WIDTH_MENU],
          output: [SMALL_WIDTH, BIG_WIDTH_MENU],
        }),
        minWidth: x.to({
          range: [SMALL_WIDTH, BIG_WIDTH_MENU],
          output: [SMALL_WIDTH, BIG_WIDTH_MENU],
        }),
        overflowY: 'auto',
        overflowX: 'hidden',
        position: 'fixed',
        zIndex: 100,
        gap: 50,
      }}
    >
      <MenuWrapper>
        <LogoWrapper>
          {logoUrl ? (
            <img src={logoUrl} width={isMobile ? 25 : 50} />
          ) : isSmall ? (
            <SmallLogo />
          ) : (
            <Logo />
          )}
        </LogoWrapper>
        <MainMenu isSmall={isSmall} toggleIsSmall={toggleIsSmall} />
        <ExpandButton isSmall={isSmall} onClick={toggleIsSmall} />
      </MenuWrapper>
      <FooterLinks visible={!smallVisible} />
    </animated.div>
  )
}

export default DesktopMenu
