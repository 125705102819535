import { FC } from 'react'
import useFormProcessor from '@hooks/useFormProcessor'
import AddNewItemModal from '@templates/AddNewItemModal'
import { CommonWorkPlaceBody, WorkPlaceDetailed } from '@models/workPlace'
import {
  useCreateWorkPlaceMutation,
  useUpdateWorkPlaceMutation,
} from '@services/workPlaces'
import getDefaultValues from './getDefaultValues'
import useWorkPlaceFields from './useWorkPlaceFields'

export type FormValues = any

type Props = {
  isEdit?: boolean
  workPlace?: WorkPlaceDetailed
  handleCloseModal: () => void
}

// TODO
const WorkPlaceModal: FC<Props> = ({
  isEdit = false,
  workPlace,
  handleCloseModal,
}) => {
  const [post, postData] = useCreateWorkPlaceMutation()
  const [put, putData] = useUpdateWorkPlaceMutation()
  const additionalProps = isEdit ? putData : postData

  const methods = useFormProcessor({
    apiErrors: additionalProps.error,
    defaultValues: getDefaultValues(workPlace),
  })

  const fields = useWorkPlaceFields()

  const handleSubmitForm = (form: FormValues) => {
    console.log(form)
    const commonForm: CommonWorkPlaceBody = {
      title: 'qq',
    }

    if (isEdit) {
      if (workPlace) {
        put({
          ...commonForm,
          id: workPlace.id,
        })
      }
    } else {
      post(commonForm)
    }
  }

  return (
    <AddNewItemModal
      {...additionalProps}
      onSubmitForm={handleSubmitForm}
      methods={methods}
      fields={fields}
      titlePostfix="рабочее место"
      isEdit={isEdit}
      handleCloseModal={handleCloseModal}
      withSaveAndAddMore
    />
  )
}

export default WorkPlaceModal
