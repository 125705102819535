import { FC, ReactNode } from 'react'
import { useToggle } from 'react-use'
import OutsideClickHandler from 'react-outside-click-handler'
import { isMobile } from '@const/device'
import Modal from '@atoms/Modal'
import { MenuWrapper, MobileProfileMenuWrapper, SelectWrapper } from './styled'

type Props = {
  Content: FC<{ expanded: boolean }>
  expandedContent: ReactNode
  withoutClose?: boolean
  desktopLeft?: number
}

const Expandable: FC<Props> = ({
  Content,
  expandedContent,
  withoutClose = false,
  desktopLeft,
}) => {
  const [expanded, toggleExpanded] = useToggle(false)

  const handleClose = () => {
    if (!withoutClose) {
      toggleExpanded(false)
    }
  }

  const menuComponent = isMobile ? (
    <Modal visible={expanded} onModalClose={handleClose}>
      <MobileProfileMenuWrapper onClick={handleClose}>
        {expandedContent}
      </MobileProfileMenuWrapper>
    </Modal>
  ) : (
    <MenuWrapper
      desktopLeft={desktopLeft}
      onClick={(event) => {
        event.stopPropagation()
        handleClose()
      }}
    >
      {expandedContent}
    </MenuWrapper>
  )

  return (
    <OutsideClickHandler onOutsideClick={() => toggleExpanded(false)}>
      <SelectWrapper>
        <div onClick={toggleExpanded}>
          <Content expanded={expanded} />
        </div>
        {expanded && menuComponent}
      </SelectWrapper>
    </OutsideClickHandler>
  )
}

export default Expandable
