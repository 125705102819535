import styled from '@emotion/styled'

export const Container = styled.div`
  z-index: 1000;
  position: relative;
`

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: flex-end;
  gap: 10px;
`

export const NameWrapper = styled.span<{
  expanded: boolean
}>`
  color: ${({
    expanded,
    theme: {
      colors: {
        text: { black, primary },
      },
    },
  }) => (expanded ? primary : black)};
  margin: ${({ theme }) => `0 ${theme.sizes.base}`};
  font-style: normal;
  font-weight: 500;
  font-size: ${({ theme }) => theme.text.h4.fontSize};
  line-height: ${({ theme }) => theme.text.h4.lineHeight};
`

export const ArrowWrapper = styled.div`
  padding-bottom: 3px;
`

export const MenuElement = styled.div`
  cursor: pointer;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  &:hover {
    color: #1d4e89;
  }
`
