import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as EmptyAvatar } from '@interface-images/empty-avatar.svg'
import useAuth from '@hooks/useAuth'
import useMe from '@hooks/useMe'
import { Routes } from '@enums/Routes'
import { isMobile } from '@const/device'
import Expandable from '@organisms/Expandable'
import { ReactComponent as ActiveArrowDown } from '@interface-images/arrow-down-active.svg'
import { ReactComponent as InactiveArrowDown } from '@interface-images/arrow-down.svg'
import {
  MenuElement,
  NameWrapper,
  ArrowWrapper,
  Wrapper,
  Container,
} from './styled'

const NameWithAvatar: FC = () => {
  const { fullName } = useMe()
  const { logout } = useAuth()
  const navigate = useNavigate()

  const handleProfile = () => {
    navigate(Routes.PROFILE)
  }

  const handleExit = () => {
    navigate(Routes.INIT)
    logout()
  }

  const name = fullName?.split(' ')[1] || fullName || 'Профиль'

  return (
    <Container>
      <Expandable
        Content={({ expanded }) => (
          <Wrapper>
            <EmptyAvatar />
            {!isMobile && <NameWrapper expanded={expanded}>{name}</NameWrapper>}
            <ArrowWrapper>
              {expanded ? <ActiveArrowDown /> : <InactiveArrowDown />}
            </ArrowWrapper>
          </Wrapper>
        )}
        expandedContent={
          <>
            <MenuElement onClick={handleProfile}>Мой профиль</MenuElement>
            <MenuElement onClick={handleExit}>Выход</MenuElement>
          </>
        }
      />
    </Container>
  )
}

export default NameWithAvatar
