import { ButtonHTMLAttributes, DetailedHTMLProps, FC, ReactNode } from 'react'
import { StyledComponent } from '@emotion/styled'
import Loader from '@atoms/Loader'
import { StyledBigButton, StyledSmallButton } from './styled'

type Props = {
  big?: boolean
  isLoading?: boolean
  leftIcon?: ReactNode
  rightIcon?: ReactNode
  autoWidth?: boolean
  withoutUpperCase?: boolean
} & DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>

const BaseButton: FC<Props> = ({
  big = false,
  isLoading = false,
  leftIcon,
  rightIcon,
  children,
  ...restProps
}) => {
  const Component: StyledComponent<Props> = big
    ? StyledBigButton
    : StyledSmallButton

  return (
    <Loader isLoading={isLoading}>
      <Component type="button" {...restProps}>
        {leftIcon && <span>{leftIcon}</span>}
        {children}
        {rightIcon && (
          <span
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            {rightIcon}
          </span>
        )}
      </Component>
    </Loader>
  )
}

export type { Props as BaseButtonProps }
export default BaseButton
