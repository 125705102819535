import { omit } from 'lodash'
import StoreTags from '@enums/StoreTags'
import { CommonQueryParams, ItemsFromResponse } from '@models/common'
import {
  ProtectionMeanByRiskMap,
  ProtectionMeanByRiskMapGrouped,
  ProtectionMeanByRiskMapGroupedOption,
  ProtectionMeanByRiskMapOption,
  ProtectionMeanConstruction,
  ProtectionMeanConstructionOption,
  ProtectionMeanType,
  ProtectionMeanTypeOption,
  SearchedDangerProtectionMean,
} from '@models/protectionMeans'
import emptySplitApi from '../api'
import { HYDRA_MEMBER, HYDRA_TOTAL_ITEMS } from '../../config/consts'

export const protectionMeansApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getProtectionMeanTypes: builder.query<
      { items: ProtectionMeanTypeOption[]; length: number },
      CommonQueryParams
    >({
      query: (params) => ({
        url: '/api/individual_protection_mean_types',
        params,
      }),
      transformResponse: (response: ItemsFromResponse<ProtectionMeanType>) => ({
        items: response[HYDRA_MEMBER].map((item) => ({
          ...item,
          label: item.title,
          value: item.title,
        })),
        length: response[HYDRA_TOTAL_ITEMS],
      }),
      providesTags: [StoreTags.PROTECTION_MEANS],
    }),
    getProtectionMeanConstructions: builder.query<
      { items: ProtectionMeanConstructionOption[]; length: number },
      CommonQueryParams
    >({
      query: (params) => ({
        url: '/api/individual_protection_mean_constructions',
        params,
      }),
      transformResponse: (
        response: ItemsFromResponse<ProtectionMeanConstruction>
      ) => ({
        items: response[HYDRA_MEMBER].map((item) => ({
          ...item,
          label: item.title,
          value: item.title,
        })),
        length: response[HYDRA_TOTAL_ITEMS],
      }),
      providesTags: [StoreTags.PROTECTION_MEANS],
    }),
    getDangersSearchedProtectionMeans: builder.query<
      {
        items: SearchedDangerProtectionMean[]
        length: number
        modalIndex?: number
      },
      {
        event: string | string[]
        construction?: string | []
        'construction.title'?: string | []
        'construction.type'?: string | []
        modalIndex?: number
        eventProtectionMeanOnlyParents?: boolean
        eventProtectionMeanOnlyChilds?: boolean
      }
    >({
      query: (params) => ({
        url: '/api/risk_map_danger_event_protection_means',
        params: omit(params, 'modalIndex'),
      }),
      transformResponse: (
        response: ItemsFromResponse<SearchedDangerProtectionMean>,
        _meta,
        params
      ) => {
        return {
          items: response[HYDRA_MEMBER].map((item) => ({
            ...item,
            label: item['@id'],
            value: item['@id'],
          })),
          length: response[HYDRA_TOTAL_ITEMS],
          modalIndex: params.modalIndex,
        }
      },
      providesTags: [StoreTags.PROTECTION_MEANS],
    }),
    getProtectionMeansByRiskMap: builder.query<
      { items: ProtectionMeanByRiskMapOption[]; length: number },
      CommonQueryParams & {
        riskMap: string
      }
    >({
      query: (params) => ({
        url: '/api/risk_map_danger_individual_protection_means',
        params,
      }),
      transformResponse: (
        response: ItemsFromResponse<ProtectionMeanByRiskMap>
      ) => ({
        items: response[HYDRA_MEMBER].map((item) => ({
          ...item,
          label: item.meanConstruction.title,
          value: item.meanConstruction.title,
        })),
        length: response[HYDRA_TOTAL_ITEMS],
      }),
      providesTags: [StoreTags.PROTECTION_MEANS],
    }),
    getProtectionMeansByRiskMapGrouped: builder.query<
      { items: ProtectionMeanByRiskMapGroupedOption[]; length: number },
      CommonQueryParams & {
        riskMap: string
      }
    >({
      query: (params) => ({
        url: '/api/risk_map_danger_individual_protection_means/grouped',
        params,
      }),
      transformResponse: (
        response: ItemsFromResponse<ProtectionMeanByRiskMapGrouped>
      ) => ({
        items: response[HYDRA_MEMBER].map((item) => ({
          ...item,
          label: item.construction,
          value: item.construction,
        })),
        length: response[HYDRA_TOTAL_ITEMS],
      }),
      providesTags: [StoreTags.PROTECTION_MEANS, StoreTags.PROTECTION_MEAN],
    }),
  }),
})

export const {
  useGetProtectionMeanTypesQuery,
  useGetProtectionMeanConstructionsQuery,
  useLazyGetDangersSearchedProtectionMeansQuery,
  useGetProtectionMeansByRiskMapGroupedQuery,
  useGetProtectionMeansByRiskMapQuery,
} = protectionMeansApi
