import { FC, MouseEventHandler, ReactNode } from 'react'
import { useToggle } from 'react-use'
import ConfirmModalContent from '@organisms/ConfirmModalContent'
import { ButtonsWrapper, TextWrapper } from '@templates/DeleteButton/styled'
import Text from '@organisms/Text'
import PrimaryButton from '@organisms/PrimaryButton'
import SecondaryButton from '@organisms/SecondaryButton'
import Modal from '@organisms/Modal'

type Props = {
  ButtonComponent: FC<{ onClick: MouseEventHandler }>
  onConfirm: MouseEventHandler
  confirmText: ReactNode
  confirmButtonText: string
  cancelText: string
}

const ButtonWithConfirm: FC<Props> = ({
  ButtonComponent,
  onConfirm,
  confirmText,
  confirmButtonText,
  cancelText,
}) => {
  const [visible, toggleVisible] = useToggle(false)

  const handleConfirm: MouseEventHandler = (e) => {
    onConfirm(e)
    toggleVisible(false)
  }

  return (
    <>
      <ButtonComponent
        onClick={(e) => {
          e.stopPropagation()
          toggleVisible(true)
        }}
      />
      <Modal visible={visible} onModalClose={toggleVisible}>
        <ConfirmModalContent
          title="Подтвердите действие"
          leftIcon="sad-bear-new.svg"
          rightContent={
            <>
              <TextWrapper>
                <Text>{confirmText}</Text>
              </TextWrapper>
              <ButtonsWrapper>
                <PrimaryButton onClick={handleConfirm} big>
                  {confirmButtonText}
                </PrimaryButton>
                <SecondaryButton onClick={toggleVisible} big>
                  {cancelText}
                </SecondaryButton>
              </ButtonsWrapper>
            </>
          }
        />
      </Modal>
    </>
  )
}

export default ButtonWithConfirm
