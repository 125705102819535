import * as React from 'react'
import { FC, useState } from 'react'
import Table, { Column } from '@organisms/Table'
import { ListPageLayoutListProps } from '@templates/ListPageLayout'
import {
  DownCircleFilled,
  DownloadOutlined,
  SettingFilled,
  UpCircleFilled,
} from '@ant-design/icons'
import ButtonStyle from '@enums/ButtonStyle'
import StyledButton from '@organs/StyledButton'
import { ActionsWrapper } from '@templates/ListPageLayout/Header/styled'
import Expandable from '@organisms/Expandable'
import CheckboxNew from '@atoms/CheckboxNew'
import EditButton from '@organisms/EditButton'
import DeleteButton from '@templates/DeleteButton'
import GrayButton from '@organisms/GrayButton'
import {
  ActionsRowWrapper,
  SettingsRow,
  SettingsWrapper,
  TableWrapper,
  VerticalTextWrapper,
} from './styled'

const VerticalText: FC<{ children: string }> = ({ children }) => (
  <VerticalTextWrapper>{children}</VerticalTextWrapper>
)

const COLUMNS = [
  {
    title: 'Общие данные',
    children: [
      {
        title: '№ РМ',
        dataIndex: 'number',
      },
      {
        title: 'Наименование профессии (должности) работника',
        dataIndex: 'title',
      },
      {
        title: '№ карты СОУТ',
        dataIndex: 'soutNumber',
      },
      {
        title: 'Дата карты',
        dataIndex: 'date',
      },
      {
        title: 'Подразделение',
        dataIndex: 'division',
      },
      {
        title: 'Должность',
        dataIndex: 'position',
      },
    ],
  },
  {
    title: 'Классы',
    verticalChildren: true,
    children: [
      {
        title: 'Итоговый класс УТ',
        dataIndex: 'classUt',
      },
      {
        title: 'Химический',
        dataIndex: 'chemical',
      },
      {
        title: 'Биологический',
        dataIndex: 'biological',
      },
      {
        title: 'Аэрозоли преимущественно фиброгенного действия',
        dataIndex: 'aero',
      },
      {
        title: 'ШУМ',
        dataIndex: 'shum',
      },
      {
        title: 'Инфразвук',
        dataIndex: 'infraSound',
      },
      {
        title: 'Ультразвук воздушный',
        dataIndex: 'ultraSoundAero',
      },
      {
        title: 'Вибрация общая',
        dataIndex: 'commonVibration',
      },
      {
        title: 'Вибрация локальная',
        dataIndex: 'localVibration',
      },
      {
        title: 'Неионизирующие излучения',
        dataIndex: 'nonIon',
      },
      {
        title: 'Ионизирующие излучения',
        dataIndex: 'ion',
      },
      {
        title: 'Параметры микроклимата',
        dataIndex: 'microClimateParams',
      },
      {
        title: 'Параметры световой среды',
        dataIndex: 'lightParams',
      },
      {
        title: 'Тяжесть трудового процесса',
        dataIndex: 'workProcessHeaving',
      },
      {
        title: 'Напряженность трудового процесса',
        dataIndex: 'workProcessStrangeness',
      },
    ],
  },
]

const getColumnToRender = (inactiveColumns: string[]): Column[] =>
  COLUMNS.reduce((acc, item) => {
    if (inactiveColumns.includes(item.title)) return acc

    const activeChildren = item.children.filter(
      (item) => !inactiveColumns.includes(item.title)
    )

    acc.push(
      item.verticalChildren
        ? {
            ...item,
            children: activeChildren.map((child: { title: string }) => ({
              ...child,
              title: <VerticalText>{child.title}</VerticalText>,
            })),
          }
        : {
            ...item,
            children: activeChildren,
          }
    )

    return acc
  }, [] as Column[])

type DataItem = {
  key: string
  number: string
  title: string
  soutNumber: string
  date: string
  division: string
  position: string
  classUt: string
  chemical: string
  biological: string
  aero: string
  shum: string
  infraSound: string
  ultraSoundAero: string
  commonVibration: string
  localVibration: string
  nonIon: string
  ion: string
  microClimateParams: string
  lightParams: string
  workProcessHeaving: string
  workProcessStrangeness: string
}

const WorkPlacesTable: FC<ListPageLayoutListProps> = ({ list }) => {
  const [inactiveColumns, setInactiveColumns] = useState<string[]>([])

  const tableData: DataItem[] = []
  for (const item of list) {
    tableData.push({
      key: JSON.stringify(item),
      number: '1',
      title: '1',
      soutNumber: '1',
      date: '1',
      division: '1',
      position: '1',
      classUt: '1',
      chemical: '1',
      biological: '1',
      aero: '1',
      shum: '1',
      infraSound: '1',
      ultraSoundAero: '1',
      commonVibration: '1',
      localVibration: '1',
      nonIon: '1',
      ion: '1',
      microClimateParams: '1',
      lightParams: '1',
      workProcessHeaving: '1',
      workProcessStrangeness: '1',
    })
  }

  const handleCheckboxClickCreator = (title: string) => (e: any) => {
    setInactiveColumns((prev) =>
      e.target.checked
        ? prev.filter((item) => item !== title)
        : [...prev, title]
    )
  }

  return (
    <TableWrapper>
      <Table
        columns={getColumnToRender(inactiveColumns)}
        data={tableData}
        expandable={{
          columnTitle: (
            <Expandable
              desktopLeft={1}
              withoutClose
              Content={() => (
                <SettingsWrapper>
                  <StyledButton
                    autoWidth
                    leftIcon={<SettingFilled />}
                    buttonStyle={ButtonStyle.PRIMARY}
                  />
                </SettingsWrapper>
              )}
              expandedContent={
                <ActionsWrapper>
                  {COLUMNS.map(({ title, children }) => {
                    return (
                      <div>
                        <SettingsRow>
                          <CheckboxNew
                            checked={
                              title
                                ? !inactiveColumns.includes(title as string)
                                : true
                            }
                            onChange={handleCheckboxClickCreator(
                              title as string
                            )}
                          />
                          {title}
                        </SettingsRow>
                        <ActionsWrapper tabbed>
                          {children.map((children) => {
                            return (
                              <SettingsRow>
                                <CheckboxNew
                                  checked={
                                    children.title
                                      ? !inactiveColumns.includes(
                                          children.title as string
                                        )
                                      : true
                                  }
                                  onChange={handleCheckboxClickCreator(
                                    children.title as string
                                  )}
                                />
                                {children.title}
                              </SettingsRow>
                            )
                          })}
                        </ActionsWrapper>
                      </div>
                    )
                  })}
                </ActionsWrapper>
              }
            />
          ),
          expandIcon: (props) => (
            <ActionsRowWrapper>
              <Expandable
                desktopLeft={1}
                withoutClose
                Content={() => (
                  <SettingsWrapper>
                    <StyledButton
                      autoWidth
                      leftIcon={<SettingFilled />}
                      buttonStyle={ButtonStyle.PRIMARY}
                    />
                  </SettingsWrapper>
                )}
                expandedContent={
                  <ActionsWrapper>
                    <EditButton />
                    <DeleteButton />
                    <GrayButton leftIcon={<DownloadOutlined />}>
                      В архив
                    </GrayButton>
                  </ActionsWrapper>
                }
              />
              <StyledButton
                autoWidth
                onClick={(e) => props.onExpand(props.record, e)}
                leftIcon={
                  props.expanded ? <UpCircleFilled /> : <DownCircleFilled />
                }
                buttonStyle={ButtonStyle.PRIMARY}
              />
            </ActionsRowWrapper>
          ),
          expandedRowRender: () => <p style={{ margin: 0 }}>qqq</p>,
        }}
      />
    </TableWrapper>
  )
}

export default WorkPlacesTable
