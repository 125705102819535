import { useEffect } from 'react'
import { RiskMapDetailed } from '@models/riskMap'
import { useAppDispatch } from '@hooks/useAppDispatch'
import { setSelectedProtectionMeansForms } from '@services/riskMapModal'
import {
  ProtectionMeanItem,
  ProtectionMeansFieldsFormValues,
} from '@modals/RiskMapModal/Dangers/DangersFields/DangersFieldsBlock/ProtectionMeansFields'
import { formatServerDate } from '@utils/date'

const useSetDefaultProtectionMeans = (riskMap: RiskMapDetailed | undefined) => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!riskMap?.dangers) return

    const selectedProtectionMeansForms = riskMap.dangers.reduce(
      (acc, { individualProtectionMeans = [] }, index) => {
        const protectionMeansResult = individualProtectionMeans.reduce(
          (accumulator, item) => {
            const {
              meanType,
              meanConstruction,
              quantity,
              updateFrequency,
              updateBeforeAt,
              etnNumber,
              additionalType,
            } = item
            accumulator.push({
              type: meanType
                ? {
                    ...meanType,
                    label: meanType.title,
                    value: meanType.title,
                  }
                : undefined,
              constructionMean: meanConstruction
                ? {
                    ...meanConstruction,
                    label: meanConstruction.title,
                    value: meanConstruction.title,
                  }
                : undefined,
              etnNumber,
              quantity: String(quantity),
              updateFrequency: String(updateFrequency),
              updateBeforeAt: formatServerDate(updateBeforeAt),
              additionalType,
              src: undefined,
              parentFormId: undefined,
              hasEqualConstructions: false,
              id: undefined,
            })

            return accumulator
          },
          [] as ProtectionMeanItem[]
        )

        acc[index] = {
          prefilled: true,
          protectionMeans: protectionMeansResult,
        }

        return acc
      },
      {} as Record<number, ProtectionMeansFieldsFormValues>
    )

    dispatch(setSelectedProtectionMeansForms(selectedProtectionMeansForms))
  }, [riskMap])
}

export default useSetDefaultProtectionMeans
