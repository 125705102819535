import { Dispatch, FC, ReactNode, SetStateAction, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { omit } from 'lodash'
import useMe from '@hooks/useMe'
import { ReactComponent as InactiveOrganization } from '@interface-images/organization.svg'
import { ReactComponent as ActiveOrganization } from '@interface-images/organization-active.svg'
import { ReactComponent as InactiveEducation } from '@interface-images/education.svg'
import { ReactComponent as ActiveEducation } from '@interface-images/education-active.svg'
import { ReactComponent as ActiveRisks } from '@main-menu/active/risks.svg'
import { ReactComponent as InactiveRisks } from '@main-menu/inactive/risks.svg'
import { Routes } from '@enums/Routes'
import MenuItem from './MenuItem'
import { Wrapper } from './styled'
import MenuAccordion from './MenuAccordion'
import {
  EDUCATION_ROUTES,
  ORGANISATION_ROUTES,
  RISK_MAPS_ROUTES,
  SOUT_ROUTES,
} from './config'
import ROUTES_CONFIG, { RouteConfig } from 'src/routes'

const getRoutes = (items: RouteConfig[], set: Set<Routes>) => {
  const filtered = items.filter((route) => set.has(route.route))

  if (filtered.length === 0) {
    return []
  }

  return filtered.map((route) => omit(route, ['InactiveIcon', 'ActiveIcon']))
}

const getAnotherRoutes = (items: RouteConfig[], excludeRoutes: RouteConfig[]) =>
  items.filter((route) =>
    excludeRoutes.every((excludeRoute) => excludeRoute.route !== route.route)
  )

type Props = {
  onItemClick?: (route: string) => void
  isSmall?: boolean
  toggleIsSmall?: Dispatch<SetStateAction<boolean>>
}

const MainMenu: FC<Props> = ({
  onItemClick,
  isSmall = false,
  toggleIsSmall,
}) => {
  const { pathname } = useLocation()
  const me = useMe()
  const { roles: myRoles = [] } = me

  const {
    organisationRoutes,
    educationRoutes,
    anotherRoutes,
    riskMapsRoutes,
    soutRoutes,
  } = useMemo(() => {
    const enabledItems = ROUTES_CONFIG.filter(
      ({ roles, text, getText, hidden }) => {
        const enabled = roles
          ? myRoles.some((myRole) => roles.has(myRole))
          : true

        const resultText = getText?.(me) || text

        return !(hidden || !enabled || !resultText)
      }
    )

    const organisationRoutes = getRoutes(enabledItems, ORGANISATION_ROUTES)
    const educationRoutes = getRoutes(enabledItems, EDUCATION_ROUTES)
    const riskMapsRoutes = getRoutes(enabledItems, RISK_MAPS_ROUTES)
    const soutRoutes = getRoutes(enabledItems, SOUT_ROUTES)

    const anotherRoutes = getAnotherRoutes(enabledItems, [
      ...organisationRoutes,
      ...educationRoutes,
      ...riskMapsRoutes,
      ...soutRoutes,
    ])

    return {
      organisationRoutes,
      educationRoutes,
      anotherRoutes,
      enabledItems,
      riskMapsRoutes,
      soutRoutes,
    }
  }, [myRoles])

  const renderSubmenu = (
    routes: Omit<RouteConfig, 'InactiveIcon' | 'ActiveIcon'>[],
    title: string,
    activeIcon: ReactNode,
    inactiveIcon: ReactNode
  ) => {
    if (routes.length === 0) {
      return null
    }

    return (
      <MenuAccordion
        isSmall={isSmall}
        toggleIsSmall={toggleIsSmall}
        title={title}
        activeIcon={activeIcon}
        inactiveIcon={inactiveIcon}
        initialExpanded={routes.some((item) => item.route === pathname)}
      >
        <Wrapper>
          {routes.map((item) => (
            <MenuItem
              {...item}
              key={item.route}
              onItemClick={onItemClick}
              isSmall={isSmall}
            />
          ))}
        </Wrapper>
      </MenuAccordion>
    )
  }

  return (
    <>
      {renderSubmenu(
        organisationRoutes,
        'Организация',
        <ActiveOrganization />,
        <InactiveOrganization />
      )}
      {renderSubmenu(
        educationRoutes,
        'Обучение',
        <ActiveEducation />,
        <InactiveEducation />
      )}
      {renderSubmenu(soutRoutes, 'СОУТ', <ActiveRisks />, <InactiveRisks />)}
      {renderSubmenu(
        riskMapsRoutes,
        'Карты рисков',
        <ActiveRisks />,
        <InactiveRisks />
      )}
      {anotherRoutes.map((item) => (
        <MenuItem
          {...item}
          key={item.route}
          onItemClick={onItemClick}
          isSmall={isSmall}
        />
      ))}
    </>
  )
}

export default MainMenu
