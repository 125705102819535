import styled from '@emotion/styled'

export const Wrapper = styled.div`
  z-index: 1050;
  background: #febb02;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0 20px;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`

export const TextWrapper = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.1em;
  color: #000000;
  align-items: center;
  padding: 24px;
  text-align: center;
  width: 100%;
`

export const CloseWrapper = styled.div`
  padding: 15px;
  cursor: pointer;
  color: white;
  font-weight: bolder;
  font-size: 16px;
`
