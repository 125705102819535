/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { FC, useEffect, useRef, useState } from 'react'
import { useMount, useSetState } from 'react-use'
import Joyride, { CallBackProps, STATUS, Step } from 'react-joyride'
import { useTheme } from '@emotion/react'
import { useAppDispatch } from '@hooks/useAppDispatch'
import { finishOnboarding, startOnboarding } from '@services/onboarding/slice'
import { useSendFeedbackMutation } from '@services/onboarding/api'
import OnboardingCodes from '@enums/OnboardingCodes'
import useMe from '@hooks/useMe'
import useUpdateMe from '@hooks/useUpdateMe'
import { useAppSelector } from '@hooks/useAppSelector'
import { setOnboardingVisited } from '@services/auth/slice'
import OnboardingFeedback from './OnboardingFeedback'
import OnboardingTooltip from './OnboardingTooltip'

type JoyStep = Step & {
  needSendFeedback?: boolean
  onOpen?: () => void
}

export type OnboardingState = {
  run: boolean
  steps: JoyStep[]
}

type Props = {
  steps: JoyStep[]
  firstStepTitle: string
  code: OnboardingCodes
  withFeedback?: boolean
  withScroll?: boolean
}

const OnboardingContent: FC<Props> = ({
  steps: stepsProp,
  firstStepTitle,
  code,
  withFeedback = false,
  withScroll = false,
}) => {
  const { userConfig } = useMe()
  const dispatch = useAppDispatch()
  const onboardingStarted = useAppSelector((state) => state.onboarding.started)
  const {
    colors: {
      main,
      background: { surface },
    },
  } = useTheme()
  const [sendFeedback] = useSendFeedbackMutation()
  const [updateMe] = useUpdateMe()

  const [text, setText] = useState<string | undefined>()
  const [rank, setRank] = useState<number | undefined>()
  const feedbackSent = useRef(false)
  const updateSent = useRef(false)

  const initialSteps: JoyStep[] = [
    {
      title: firstStepTitle,
      placement: 'center',
      target: 'body',
      content: '',
    },
    ...stepsProp,
  ]

  if (withFeedback) {
    initialSteps.push({
      title: 'Расскажите, все ли в разделе удобно?',
      placement: 'center',
      target: 'body',
      needSendFeedback: true,
      content: <OnboardingFeedback setText={setText} setRank={setRank} />,
    })
  }

  const [{ run, steps }, setState] = useSetState<OnboardingState>({
    run: false,
    steps: initialSteps,
  })

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status, action, step } = data

    if (withScroll) {
      // @ts-ignore
      document.querySelector(step.target)?.scrollIntoView(false)
    }

    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED]

    // @ts-ignore
    step.onOpen?.()

    if (finishedStatuses.includes(status) || action === 'close') {
      // @ts-ignore
      if (step.needSendFeedback && !feedbackSent.current && (text || rank)) {
        void sendFeedback({
          code,
          text,
          rank,
        })
        feedbackSent.current = true
      }

      setState({ run: false })

      if (!updateSent.current) {
        dispatch(
          setOnboardingVisited({
            [code]: true,
          })
        )
        updateMe({
          userConfig: {
            ...userConfig,
            onboarding: {
              ...userConfig?.onboarding,
              [code]: true,
            },
          },
        })
        updateSent.current = true
      }

      dispatch(finishOnboarding())
    }
  }

  useMount(() => {
    dispatch(startOnboarding(code))
  })

  useEffect(() => {
    if (onboardingStarted === code) {
      setState({
        run: true,
      })
    }
  }, [code, onboardingStarted, setState])

  if (!run) {
    return null
  }

  return (
    <Joyride
      tooltipComponent={OnboardingTooltip}
      beaconComponent={() => null}
      callback={handleJoyrideCallback}
      continuous
      run={run}
      steps={steps}
      styles={{
        options: {
          arrowColor: main,
          backgroundColor: main,
          primaryColor: surface,
          textColor: surface,
          zIndex: 1100,
        },
        overlay: {
          height: '100vh',
        },
      }}
      disableScrolling
      spotlightPadding={4}
    />
  )
}

export type { Props as OnboardingProps, JoyStep }
export default OnboardingContent
