import { FC, ReactNode } from 'react'
import { Table } from 'antd'
import { TableProps } from 'antd/lib/table/Table'
import { Wrapper } from './styled'

type Column = {
  title: ReactNode
  dataIndex?: string
  key?: string
  width?: number
  children?: Column[]
}

type TableData = Record<string, ReactNode> & {
  key: string
}

type Props = {
  columns: Column[]
  data: TableData[]
  isLoading?: boolean
} & TableProps<any>

const TableComponent: FC<Props> = ({
  columns,
  data,
  isLoading,
  pagination = false,
  ...rest
}) => {
  return (
    <Wrapper>
      <Table
        {...rest}
        dataSource={data}
        columns={columns}
        pagination={pagination}
        bordered={false}
        loading={isLoading}
        rowClassName={(record) => record.className || ''}
      />
    </Wrapper>
  )
}

export type { TableData, Column }
export default TableComponent
