import styled from '@emotion/styled'

export const Wrapper = styled.div`
  padding: 20px 0;
`

export const UpdateAllWrapper = styled.div`
  display: flex;
  margin-bottom: 15px;
`

export const DangerEventsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

export const DangerEvent = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 10px;
`
