import 'antd/dist/antd.less'
import './style.css'
import './fonts/Gilroy/stylesheet.css'
import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { ConfigProvider } from 'antd'
import locale from 'antd/es/locale/ru_RU'
import { createRoot } from 'react-dom/client'
import { ThemeProvider } from '@emotion/react'
import App from './App'
import theme from './theme'
import { store } from './store'

const domNode = document.querySelector('#root')
domNode?.setAttribute('notranslate', 'true')
const root = createRoot(domNode!)

root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <ConfigProvider locale={locale}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ConfigProvider>
    </ThemeProvider>
  </Provider>
)
