import {
  DetailedHTMLProps,
  forwardRef,
  ForwardRefExoticComponent,
  InputHTMLAttributes,
  RefAttributes,
} from 'react'
import { StyledCheckbox } from './styled'

type Props = {
  big?: boolean
} & DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>

const CheckboxNew: ForwardRefExoticComponent<
  DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> &
    RefAttributes<HTMLInputElement>
> = forwardRef((props, ref) => {
  return <StyledCheckbox {...props} ref={ref} type="checkbox" />
})

export { type Props as CheckboxNewProps }
export default CheckboxNew
