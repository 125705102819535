import styled from '@emotion/styled'

export const StyledBaseButton = styled.button<{
  withoutUpperCase?: boolean
  autoWidth?: boolean
}>`
  font-style: normal;
  display: flex;
  align-items: center;
  align-content: center;
  flex-wrap: nowrap;
  white-space: nowrap;
  gap: 6.5px;
  cursor: pointer;

  width: ${({ autoWidth }) => (autoWidth ? 'auto' : '100%')};
  ${({ withoutUpperCase }) =>
    withoutUpperCase ? '' : 'letter-spacing: 0.1em; text-transform: uppercase;'}
  &:disabled {
    cursor: not-allowed;
    background: #f2f6fa;
    color: #c7c7c7;
    border: 1px solid #c7c7c7;
    box-shadow: none;

    &:hover {
      cursor: not-allowed;
      background: #f2f6fa;
      color: white;
      box-shadow: none;
      border: none;
    }
  }
`

export const StyledBigButton = styled(StyledBaseButton)`
  height: 58px;
  border-radius: 32px;
  padding: 20px 26px;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
`

export const StyledSmallButton = styled(StyledBaseButton)`
  height: 40px;
  border-radius: 30px;
  padding: 12px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
`
