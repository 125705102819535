import { add } from 'date-fns'
import { SearchedDangerProtectionMean } from '@models/protectionMeans'
import { ProtectionMeanItem } from '@modals/RiskMapModal/Dangers/DangersFields/DangersFieldsBlock/ProtectionMeansFields'
import { toClientDate } from '@utils/date'

export const convertMeanToForm = (
  mean: SearchedDangerProtectionMean,
  additional = {} as any
): ProtectionMeanItem => {
  return {
    type: mean.construction?.type?.title
      ? {
          ...mean.construction.type,
          label: mean.construction.type.title,
          value: mean.construction.type['@id'],
        }
      : undefined,
    constructionMean: mean.construction?.title
      ? {
          ...mean.construction,
          label: mean.construction.title,
          value: mean.construction.title,
        }
      : undefined,
    quantity: mean.issueNorm ? String(mean.issueNorm) : undefined,
    updateFrequency: mean.updateFrequency
      ? String(mean.updateFrequency)
      : undefined,
    updateBeforeAt: mean.updateFrequency
      ? toClientDate(add(new Date(), { months: mean.updateFrequency }))
      : undefined,
    hasEqualConstructions: mean.defaultMean,
    additionalType: mean.additionalType,
    src: mean,
    ...(mean.etnNumber ? { etnNumber: mean.etnNumber } : {}),
    ...additional,
  }
}
