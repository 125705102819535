import { textField } from '@templates/AddNewItemModal/templates'
import { Fields } from '@templates/AddNewItemModal/Content'

const useWorkPlaceFields = (): Fields => {
  return [
    {
      fields: [
        textField({
          name: 'title',
          label: 'Название темы',
          isRequired: true,
          placeholder: 'Название темы',
        }),
      ],
    },
  ]
}

export default useWorkPlaceFields
