import styled from '@emotion/styled'

export const Wrapper = styled.div`
  padding: 20px 0;
`

export const AuthorWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;

  span {
    white-space: nowrap;
  }
`
