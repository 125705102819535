import { FC } from 'react'
import useFormProcessor from '@hooks/useFormProcessor'
import DownloadExampleButton from '@templates/DownloadExampleButton'
import AddNewItemModal from '@templates/AddNewItemModal'
import FileErrorComponent from '@templates/FileErrorComponent'
import { File } from '@models/file'
import { useCreateWorkPlacesFromXmlMutation } from '@services/workPlaces'
import useWorkPlacesFromFileXmlFields from './useWorkPlacesFromFileXmlFields'

type FormValues = {
  file: File
}

type Props = {
  handleCloseModal: () => void
}

// TODO
const WorkPlacesFromFileXlsModal: FC<Props> = ({ handleCloseModal }) => {
  const [post, postData] = useCreateWorkPlacesFromXmlMutation()

  const methods = useFormProcessor()
  const fields = useWorkPlacesFromFileXmlFields()

  const handleSubmit = (form: FormValues) => {
    void post({
      file: form.file?.['@id'],
    })
  }

  return (
    <AddNewItemModal
      {...postData}
      customFullTitle="Загрузка рабочих мест из .xml"
      contentBeforeFields={
        <DownloadExampleButton
          templateLink="./import_risk_maps_template.xlsx"
          title="Школа экспертов — Шаблон импорта рабочих мест.xml"
          description="Загрузите файл .xml, составленный по образцу. После загрузки будут созданы рабочие места из файла."
        />
      }
      methods={methods}
      onSubmitForm={handleSubmit}
      fields={fields}
      handleCloseModal={handleCloseModal}
      CustomErrorComponent={FileErrorComponent}
      successContentAddTitle="Все рабочие места успешно добавлены"
    />
  )
}

export default WorkPlacesFromFileXlsModal
