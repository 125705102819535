import { Routes } from '@enums/Routes'

export const ORGANISATION_ROUTES = new Set([
  Routes.COMPANY_GROUPS,
  Routes.COMPANIES,
  Routes.FILIALS,
  Routes.DIVISIONS,
  Routes.POSITIONS,
  Routes.EMPLOYEES,
])

export const EDUCATION_ROUTES = new Set([
  Routes.STUDY,
  Routes.EMPLOYEES_EDUCATION,
  Routes.CERTIFICATES,
])

export const RISK_MAPS_ROUTES = new Set([
  Routes.RISKS_MAPS,
  Routes.RISKS_MAPS_REVIEW,
])

export const SOUT_ROUTES = new Set([Routes.WORK_PLACES])
