import styled from '@emotion/styled'

const SMALL_SIZE = 20
const BIG_SIZE = 35

const getSize = ({ big }: { big?: boolean }) => (big ? BIG_SIZE : SMALL_SIZE)

export const StyledCheckbox = styled.input<{
  big?: boolean
}>`
  border: 1px solid ${({ theme }) => theme.colors.disable};
  box-sizing: border-box;
  border-radius: 2px;
  cursor: pointer;

  min-height: ${getSize}px;
  max-height: ${getSize}px;
  min-width: ${getSize}px;
  max-width: ${getSize}px;
  height: ${getSize}px;
  width: ${getSize}px;

  accent-color: ${({ theme }) => theme.colors.main};
`
