import {
  DetailedHTMLProps,
  forwardRef,
  ForwardRefExoticComponent,
  InputHTMLAttributes,
  RefAttributes,
  useEffect,
  useState,
} from 'react'
import { noop } from 'lodash'
import { FilterItem } from '@hooks/useItemsFilters'
import { StyledCheckbox, TextWrapper, Wrapper } from './styled'

type Props = {
  big?: boolean
  label?: string
  valueOnChecked?: FilterItem
  value?: any
  onChange?: (newValue: any) => void
} & Omit<
  DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  'value' | 'onChange'
>

/**
 * @deprecated use @atoms/CheckboxNew
 */
const Checkbox: ForwardRefExoticComponent<
  Props & RefAttributes<HTMLInputElement>
> = forwardRef(
  ({ onChange = noop, valueOnChecked = true, label, ...restProps }, ref) => {
    const [checked, toggleChecked] = useState<boolean>(!!restProps.value)

    useEffect(() => {
      if (checked !== !!restProps.value) {
        toggleChecked(!!restProps.value)
      }
    }, [restProps.value])

    return (
      <Wrapper
        onClick={() => {
          toggleChecked((prev) => {
            const nextValue = !prev
            onChange?.(nextValue ? valueOnChecked : false)
            return nextValue
          })
        }}
      >
        <StyledCheckbox
          {...restProps}
          onChange={noop}
          ref={ref}
          checked={checked}
          type="checkbox"
        />
        {label && <TextWrapper>{label}</TextWrapper>}
      </Wrapper>
    )
  }
)

export type CheckboxProps = Props
export default Checkbox
